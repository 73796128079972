import {useContext} from "react";
import {OnAirContext} from "../contexts/onAirContext";
import axios from "axios";
import {onAirNowAndBeforeUrl, thumbSrcPath} from "../fns/params";

const useOnAir = () => {
    const [state, setState] = useContext(OnAirContext)

    const fetchData = () => {
        if (state.isLoaded) {
            return
        }
        if (state.requestStarted) {
            return
        }

        setState({...state, requestStarted: true})

        axios({
            url: onAirNowAndBeforeUrl,
            method: 'GET',
        }).then(response => {
            const data = response.data.data
            const now = {
                ts: data.now.ts,
                author: data.now.who,
                title: data.now.what,
                thumb: thumbSrcPath(data.now.cover)
            }
            const before = data.before.map(i => {
                return {
                    ts: i.ts,
                    author: i.who,
                    title: i.what,
                    cover: thumbSrcPath(i.cover)
                }
            })

            setState({...state, now: now, before: before, isLoaded: true, requestStarted: false})
        }, error => {
            setState(state => ({...state, isLoaded: false, requestStarted: false}))
        })
    }

    const setIsLoaded = () => {
        setState({...state, isLoaded: true})
    }
    const setIsNotLoaded = () => {
        setState({...state, isLoaded: false})
    }

    return {
        isLoaded: state.isLoaded,
        now: state.now,
        before: state.before,
        fetch: fetchData,
        setIsLoaded,
        setIsNotLoaded,
    }
}

export default useOnAir