import React, {useEffect} from "react"
import useOnAir from "../hooks/useOnAir";

function OnAir() {
    const {now, isLoaded, fetch} = useOnAir()

    useEffect(() => {
        fetch()
    },[])

    setInterval(fetch, 40 * 1000)

    return isLoaded ? (
        <section className={"onAir"}>
            <div id="currentSongInfo">
                <span id="currentSongName">
                    {now.title}
                </span>
                <span id="currentSongAuthor">
                    {now.author}
                </span>
            </div>
            <figure>
                <img src={now.thumb}
                     alt={"Okładka: " + now.title + ' - ' + now.author}/>
            </figure>
        </section>
    ) : null
}

export default OnAir